import { Button, Popconfirm, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { deleteBlogAdmin, getBlogListAdmin } from "../../services/Api/Api";
import styles from "./Blog.module.css";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { truncate } from "lodash";

const BlogList = () => {
  const [blogListItems, setBlogListItems] = useState([] || data);
  const [pageCounter, setPageCounter] = useState(1);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 0,
    total: 0,
  });

  const fetchBlogList = async () => {
    const getBlogListItem = await getBlogListAdmin({
      pageValue: pageCounter,
    });
    console.log("getBlogListItem: ", getBlogListItem);
    await setBlogListItems(getBlogListItem.data.results);
    await setPagination((pagination) => ({
      ...pagination,
      current: getBlogListItem.data.page,
      pageSize: getBlogListItem.data.limit,
      total: getBlogListItem.data.totalPages,
    }));
  };

  useEffect(() => {
    
    fetchBlogList();
  }, []);

  const onChangeTable = (pagination) => {
    console.log("params", pagination);
  };
  /* const parse = require("html-react-parser"); */

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, record) => (
        <div title={record.title}>
          {truncate(record.title, {
            length: 300,
          })}
        </div>
      ),
    },
    {
      title: "Author",
      dataIndex: "author",
      key: "author",
      render: (_, record) => (
        <div title={record.author}>
          {truncate(record.author, {
            length: 300,
          })}
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_, record) => (
        <div>
          {parse(
            truncate(record.description, {
              length: 300,
            })
          )}
        </div>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <a>Invite {record.id}</a> */}
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => deleteBlogApi(record.id)}
          >
            <a>Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      title: "John Brown",
      description: 32,
      status: ["nice", "developer"],
    },
    {
      key: "2",
      title: "Jim Green",
      description: 42,
      address: "London No. 1 Lake Park",
      status: ["loser"],
    },
    {
      key: "3",
      title: "Joe Black",
      description: 32,
      address: "Sydney No. 1 Lake Park",
      status: ["cool", "teacher"],
    },
  ];

  const deleteBlogApi =  async (value) => {
    const Response = await deleteBlogAdmin(value);
    fetchBlogList();
  };

  return (
    <>
      <div className={styles.BlogHeaderDiv}>
        <h2>Blog</h2>
        <Link to="/create">
          <Button className="createBtn">Create</Button>
        </Link>
      </div>
      <Table
        columns={columns}
        dataSource={blogListItems}
        onChange={onChangeTable}
        pagination={pagination}
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: (event) => {
        //       // console.log("row clicked");
        //     },
        //   };
        // }}
      />
    </>
  );
};

export default BlogList;
