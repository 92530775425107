import React, { useEffect, useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";

import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const { Header, Sider, Content } = Layout;

const AppLayout = ({ children }) => {
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const [urlSnippet, setUrlSnippet] = useState(
    history.location.pathname || "1"
  );
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    const location = history.location.pathname;
    if (location === "/dashboard") {
      setUrlSnippet("1");
    } else if (location === "/blog") {
      setUrlSnippet("2");
    } else {
      setUrlSnippet("3");
    }
  });

  return (
    <Layout className="MainLayout">
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">O2XC</div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[urlSnippet]}
          items={[
            {
              key: "2",
              //   icon: <VideoCameraOutlined />,
              label: <Link to="/blog">Blog</Link>,
            },
            // {
            //   key: "3",
            //   //   icon: <UploadOutlined />,
            //   label: <Link to="/create">create-blog</Link>,
            // },
            {
              key: "3",
              //   icon: <VideoCameraOutlined />,
              label: (
                <Link to="/" onClick={() => localStorage.clear()}>
                  Logout
                </Link>
              ),
            },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header style={{ padding: 0, background: colorBgContainer }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <div>{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
