import { Button, Form, Input } from "antd";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { createBlogAdmin } from "../../services/Api/Api";
import { useHistory } from "react-router-dom";

function CreateBlog() {
  const history = useHistory();
  const onFinish = async (values) => {
    console.log(values);
    const createBlogRes = await createBlogAdmin(values);
    if (createBlogRes.status === 201) {
      history.push("/blog");
    }
  };
  return (
    <>
      <div>
        <Form
          name="basic"
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 22 }}
          // style={{ maxHeight: "100vh" }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="createBlogForm"
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Please input your title!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Author"
            name="author"
            rules={[{ required: true, message: "Please input author!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[
              { required: true, message: "Please input your description!" },
            ]}
          >
            <ReactQuill className="ReactQuillInput" />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 2, span: 22 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default CreateBlog;
