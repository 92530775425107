import React from "react";
import { Switch, Route } from "react-router-dom";
import AppLayout from "./components/layout/AppLayout";
import BlogList from "./views/Blog/Blog";
import ContentLayout from "./views/ContentLayout/ContentLayout";
import CreateBlog from "./views/CreateBlog/CreateBlog";
import Dashboard from "./views/Dashboard/Dashboard";

export default function MainScreenRoutes() {
  return (
    <Switch>
      {/* Without Layout Start */}
      <Route exact path="/" component={ContentLayout} />
      {/* Without Layout End */}

      {/* With Layout Start */}
      <AppLayout>
        <Route path="/blog" component={BlogList} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/create" component={CreateBlog} />
      </AppLayout>
      {/* With Layout End */}
    </Switch>
  );
}
