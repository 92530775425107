import axios from "axios";

const api = axios.create({
  baseURL: "https://api.o2xc.capital/api/v1",
});

api.interceptors.request.use(
  function (config) {
    if (config.url !== "/auth/signin") {
      // config.headers.Authorization
      const token = JSON.parse(localStorage.getItem("Token"));
      config.headers.Authorization = `Bearer ${token.access.token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const getBlogList = async () => {
  try {
    const response = await api
      .get("/api/blogList")
      .then((result) => {
        console.log("result: ", result);
        return result;
      })
      .catch((err) => {
        console.log("err: ", err);
        return false;
      });
    return response.data;
  } catch (error) {
    console.log("error: ", error);
    return error;
  }
};

export const loginAdmin = async (data) => {
  try {
    const response = await api
      .post("/auth/signin", data)
      .then((result) => {
        console.log("result: ", result);
        return result;
      })
      .catch((err) => {
        console.log("err: ", err);
        return false;
      });
    return response;
  } catch (error) {
    console.log("error: ", error);
    return error;
  }
};

export const getBlogListAdmin = async ({ pageValue }) => {
  try {
    const response = await api
      .get(`/blog?page=${pageValue}`)
      .then((result) => {
        console.log("result: ", result);
        return result;
      })
      .catch((err) => {
        console.log("err: ", err);
        return false;
      });
    return response;
  } catch (error) {
    console.log("error: ", error);
    return error;
  }
};

export const createBlogAdmin = async (data) => {
  console.log("data: ", data);
  try {
    const response = await api
      .post("/blog/create", data)
      .then((result) => {
        console.log("result: ", result);
        return result;
      })
      .catch((err) => {
        console.log("err: ", err);
        return false;
      });
    return response;
  } catch (error) {
    console.log("error: ", error);
    return error;
  }
};

export const deleteBlogAdmin = async (data) => {
  try {
    const response = await api
      .delete(`/blog/delete/${data}`)
      .then((result) => {
        console.log("result: ", result);
        return result;
      })
      .catch((err) => {
        console.log("err: ", err);
        return false;
      });
    return response;
  } catch (error) {
    console.log("error: ", error);
    return error;
  }
};
