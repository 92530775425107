import React, { useRef, useState } from "react";
import { Button, Form, Input } from "antd";
import styles from "./ContentLayout.module.css";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import ReCAPTCHA from "react-google-recaptcha";
import { loginAdmin } from "../../services/Api/Api";
import { validateEmail } from "../../utils/globalFunction";
import { useHistory } from "react-router-dom";

export default function ContentLayout() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [setTokenRecaptcha] = useState("");
  const reCaptchaRef = useRef();
  const history = useHistory();

  const onFinish = async () => {
    const EmailResult = validateEmail(email);
    if (EmailResult !== true) {
      alert("Email not valid");
    }
    const data = {
      email,
      password,
    };

    console.log("login pressed");
    const Response = await loginAdmin(data);
    console.log("loginData: ", Response);
    if (Response.status === 200) {
      history.push("/blog");

      localStorage.setItem("Token", JSON.stringify(Response.data.tokens));
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function onChange(value) {
    console.log("value: ", value);
    setTokenRecaptcha(value);
  }

  return (
    <div>
      <div className={styles.contentLayout_bg}>
        <div className={styles.o2x_heading}>WELCOME TO O2XC</div>
        <div className={styles.login_div_design}>
          <div className={styles.login_div}>
            <div className={styles.loginText}>PLEASE LOG IN</div>
            <Form
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="loginForm"
            >
              <div className="data">
                <label>
                  <b className="asterisk">*</b> Email
                </label>

                <Form.Item
                  type="text"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    prefix={<MailOutlined />}
                  />
                </Form.Item>
              </div>
              <div className="data">
                <label>
                  <b className="asterisk">*</b> Password
                </label>

                <Form.Item
                  type="password"
                  // name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    prefix={<LockOutlined />}
                  />
                </Form.Item>
              </div>
              <div className="data">
                <Form.Item
                  name="reCAPTCHA"
                  rules={[
                    {
                      required: true,
                      message: "reCAPTCHA is required",
                    },
                  ]}
                >
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    sitekey="6Ldz79wkAAAAAJk3r4vDeaqEyl6-sQsCom6WjgvR"
                    size="normal"
                    onChange={onChange}
                    onExpired={(e) => setTokenRecaptcha("")}
                    onErrored={(e) => setTokenRecaptcha("")}
                  />
                </Form.Item>
              </div>

              {/* <div className="btn login"> */}
              <div className="inner"></div>

              <Button type="primary" htmlType="submit" className="button-login">
                Login
              </Button>
              {/* </div> */}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
